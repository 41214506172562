<template>
  <div class="mx-auto max-w-screen-lg  border-black">
    <div class="navbar bg-base-100">
      <div class="flex-1">
        <h1 class="text-2xl">PDF Extract Links - extract links from your pdf free online</h1>
      </div>
      <div class="flex-none">
      </div>
    </div>
    <!-- <div class="flex w-full justify-center  mt-10 mb-10">
    </div> -->
    <div class="tabs tabs-lifted tabs-lg">
      <input type="radio" name="my_tabs_2" class="tab" aria-label="Choose File" value="file" v-model="mode" checked />
      <div class="tab-content bg-base-100 border-base-300 rounded-box p-10">
        <div class="form-control w-full">
          <input type="file" class="file-input file-input-bordered w-full" @change="handleFileChange" accept=".pdf" />
        </div>
      </div>

      <input type="radio" name="my_tabs_2" class="tab" aria-label="From Url" value="url" v-model="mode" />
      <div class="tab-content bg-base-100 border-base-300 rounded-box p-10">
        <input type="text" placeholder="Enter the URL of the PDF file." v-model="inputPdfUrl"
          class="input input-bordered w-full" />
      </div>
    </div>

    <div class="flex w-full justify-center  mt-5 mb-5" v-show="errorMsg.length !== 0">
      <div class="alert alert-error">
        <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <span>{{ errorMsg }}</span>
      </div>
    </div>
    <div class="flex w-full justify-center mt-5 mb-5">
      <button class="btn btn-primary" @click="extract()">Extract Links</button>
    </div>
    <div class="flex w-full justify-center mt-5 mb-5" v-show="extracting">
      <progress class="progress progress-error w-56"></progress>
    </div>

    <div class="flex flex-col mt-5 mb-5" v-show="extractDone">
      <span class="mb-2">extract <strong class=" text-red-600">{{ linksCount }}</strong> links from pdf file.</span>
      <textarea class="textarea textarea-bordered w-full" rows="5" :value="linksContent"></textarea>
    </div>

    <div class="flex w-full mt-10 mb-10">
      <!-- <h2 class="text-2xl">FAQ</h2> -->
      <div class="join join-vertical w-full">
        <div class="collapse collapse-arrow join-item border border-base-300">
          <input type="radio" name="my-accordion-4" checked="checked" />
          <div class="collapse-title text-xl font-medium">
            How do I extract a list of links from a PDF file?
          </div>
          <div class="collapse-content">
            <p>1.choose a pdf file from your computer</p>
            <p>2.press "Extract Links",waiting...</p>
            <p>3.success,copy links</p>
          </div>
        </div>
        <div class="collapse collapse-arrow join-item border border-base-300">
          <input type="radio" name="my-accordion-4" />
          <div class="collapse-title text-xl font-medium">
            How do I extract a list of links from a PDF url?
          </div>
          <div class="collapse-content">
            <p>1.enter pdf file url</p>
            <p>2.press "Extract Links",waiting...</p>
            <p>3.success,copy links</p>
          </div>
        </div>
      </div>
    </div>

    <footer class="footer footer-center p-4 bg-base-300 text-base-content">
      <aside>
        <p>Copyright © 2008-2023 - All right reserved by PDF Extract Links</p>
      </aside>
    </footer>

  </div>
</template>

<script setup>
import { ref } from "vue"
import * as pdfjsLib from "pdfjs-dist/build/pdf";
// let pdfDoc = reactive({});
let pdfPages = ref(0);
let pdfUrl = ref("");
const workerSrc = require('pdfjs-dist/build/pdf.worker.entry.js');
let extracting = ref(false)
let extractDone = ref(false)
let linksContent = ''
let errorMsg = ref('')
let linksCount = ref(0)
let inputPdfUrl = ref("")
let mode = ref('file')
let file = null

function handleFileChange(event) {
  file = event.target.files[0];
}

async function extract() {
  errorMsg.value = ''
  linksContent = ''
  if (mode.value === 'url') {
    console.log("inputPdfUrl:", inputPdfUrl.value)
    if (inputPdfUrl.value.length === 0) {
      errorMsg.value = 'Please Enter the URL of the PDF file.'
      return
    }
    pdfUrl = inputPdfUrl
    extractWithUrl()
  }
  else {
    if (!file) {
      errorMsg.value = 'Please Choose a PDF file.'
      return
    }
    extractWithFile(file)
  }

}

async function extractWithUrl() {
  extracting.value = true
  linksCount.value = 0

  try {
    let links = await extractLinks(pdfUrl.value)
    linksCount.value = links.length
    extractDone.value = true
    extracting.value = false
    linksContent = ''
    links.forEach((link) => {
      if (linksContent.length > 0) {
        linksContent = linksContent + '\n'
      }
      linksContent = linksContent + link.link
    })
  } catch (error) {
    // console.log(error)
    extracting.value = false
    errorMsg.value = error.message
  }
}
async function extractWithFile(uploadFile) {
  const fileReader = new FileReader();
  fileReader.onload = async () => {
    const typedArray = new Uint8Array(fileReader.result);
    extracting.value = true
    linksCount.value = 0
    try {
      let links = await extractLinks(typedArray)
      // console.log(links)
      linksCount.value = links.length
      extractDone.value = true
      extracting.value = false
      linksContent = ''
      links.forEach((link) => {
        if (linksContent.length > 0) {
          linksContent = linksContent + '\n'
        }
        linksContent = linksContent + link.link
      })
    } catch (error) {
      // console.log(error)
      extracting.value = false
      errorMsg.value = error.message
    }

  };

  fileReader.readAsArrayBuffer(uploadFile);
}

const extractLinks = async (url) => {
  pdfjsLib.GlobalWorkerOptions.workerSrc = workerSrc;
  const pdf = await pdfjsLib.getDocument(url).promise;
  pdfPages.value = pdf.numPages;
  let links = [];

  const getAnnotationsPromises = Array.from({ length: pdf.numPages }, (_, i) => i + 1)
    .map((pageNum) =>
      pdf.getPage(pageNum).then((page) =>
        page.getAnnotations().then((annotations) => {
          annotations.forEach((annotation) => {
            if (annotation.subtype === "Link" && annotation.url) {
              // console.log(annotation.url)
              links.push({ link: annotation.url, text: "" });
            }
          });
        })
      )
    );

  await Promise.all(getAnnotationsPromises);
  return links;
};

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
  margin-top: 10px;
}
</style>
